<template>
  <v-stepper v-model="e1">
    <b-modal
      id="modal-1"
      :title="msgModaleTitle"
      ref="msg-modal"
      ok-only
      :no-close-on-backdrop="true"
    >
      <p class="my-4">{{ msg }}</p>
    </b-modal>
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Generate Question
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2">Add Control Number and Submit</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <b-form
          @submit.prevent="onSubmitQuestion"
          @reset.prevent="resetForm"
          v-if="show"
          id="form-stage-1-id"
        >
          <div class="row">
            <div class="col-sm-2">
              <label for="cc-ques-no"
                >CC Question Number<span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <b-form-input
                v-model="cc_ques_no"
                id="cc-ques-no"
                type="text"
                :disabled="
                  (!Boolean(ques_step_status) && ques_id > 0) ||
                    (ques_step_status == 'published' && ques_id > 0)
                "
              />
            </div>
            <div class="col-sm-2">
              <label for="cc-mapping-id"
                >CC Integrated Reference
                <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <b-form-input
                v-model="ccMapping"
                id="cc-mapping-id"
                type="text"
                :disabled="
                  (!Boolean(ques_step_status) && ques_id > 0) ||
                    (ques_step_status == 'published' && ques_id > 0)
                "
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="cc-mapping-id"
                >Questionnaire <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <b-form-select
                v-model="questionnaireSelected"
                :options="questionnaires"
                id="questionnaire"
                type="text"
                @change="getTopics(true)"
              />
            </div>
            <div class="col-sm-2">
              <label for="topic-id"
                >Topics <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <b-form-select
                id="topic-id"
                v-model="topicSelected"
                :options="topics"
              >
              </b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="question-text-id"
                >Question Text <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-10">
              <b-form-textarea
                id="question-text-id"
                v-model="questionText"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="excerpt-id">Excerpt</label>
            </div>
            <div class="col-sm-10">
              <b-form-textarea
                id="excerpt-id"
                v-model="excerpt"
                maxlength="100"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="question-help-text-id"
                >Question Attachment Text</label
              >
            </div>
            <div class="col-sm-10">
              <b-form-textarea
                id="question-help-text-id"
                v-model="questionHelpText"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="regulation-id"
                >Regulation <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <v-autocomplete
                v-model="regulationSelected"
                :items="regulations"
                chips
                small-chips
                label="Select Regulations"
                multiple
                solo
              ></v-autocomplete>
            </div>
            <div class="col-sm-2">
              <label for="platform-id">Platform</label>
            </div>
            <div class="col-sm-4">
              <v-autocomplete
                v-model="platformSelected"
                :items="platforms"
                chips
                small-chips
                label="Select Platforms"
                multiple
                solo
                id="platform-id"
              ></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="environment-id">Environment</label>
            </div>
            <div class="col-sm-4">
              <v-autocomplete
                v-model="environmentSelected"
                :items="environments"
                chips
                small-chips
                label="Select Environments"
                multiple
                solo
                id="environment-id"
              ></v-autocomplete>
            </div>
            <div class="col-sm-2">
              <label for="tag-id">Tags</label>
            </div>
            <div class="col-sm-4">
              <v-autocomplete
                v-model="tagSelected"
                :items="tags"
                chips
                small-chips
                label="Select Tags"
                multiple
                solo
                id="tag-id"
              ></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label for="expired-in-id"
                >Evidence Expires in <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-sm-4">
              <b-form-select
                id="expired-in-id"
                v-model="expiredInSelected"
                :options="expiredIns"
              >
              </b-form-select>
            </div>
            <div class="col-sm-2">
              <label for="critical-question-id">Critical Question</label>
            </div>
            <div class="col-sm-4">
              <b-form-select
                id="critical-question-id"
                v-model="criticalQuestionSelected"
                :options="criticalQuestions"
              >
              </b-form-select>
            </div>
          </div>
          <div class="row float-right">
            <div class="col-sm-12">
              <v-btn color="primary" type="submit" :loading="loading">
                Continue
              </v-btn>
            </div>
          </div>
        </b-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <b-form
          @submit.prevent="onSubmitControlMapping"
          @reset.prevent="resetForm"
          v-if="show"
          id="form-stage-3-id"
        >
          <div v-if="regulationSelected.length > 0">
            <div
              v-for="regulationId in regulationSelected"
              v-bind:key="regulationId"
            >
              <div class="row mb-5">
                <div class="col-sm-3">
                  <label for="question_text_id">
                    {{ getRegulationNameById(regulationId) }}
                    Control Numbers <span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-sm-9">
                  <b-form-textarea
                    :ref="'cc_control_num_' + regulationId"
                    v-bind:model="`cc_control_num_${regulationId}`"
                    placeholder="Please enter control numbers here separated by comma"
                    required
                    :value="
                      controlNos != undefined &&
                      controlNos[regulationId] != undefined &&
                      controlNos[regulationId].length > 0
                        ? controlNos[regulationId].toString()
                        : ''
                    "
                  ></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <v-btn color="primary" @click="e1 = 1">
                  Back
                </v-btn>
              </div>
              <div class="col-sm-8"></div>
              <div class="col-sm-2 d-flex flex-row-reverse">
                <v-btn color="primary" type="submit">
                  Save
                </v-btn>
              </div>
            </div>
          </div>
        </b-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import CCApiService from "@/core/services/aws.ccapi.service";

export default {
  data() {
    return {
      ques_id: 0,
      cc_ques_no: "",
      e1: 1,
      loading: false,
      show: true,
      regulationSelected: [],
      regulations: [],
      topics: [],
      questionnaires: [],
      topicSelected: null,
      questionnaireSelected: 1,
      platformSelected: [],
      environmentSelected: [],
      expiredInSelected: 90,
      tagSelected: [],
      questionText: "",
      questionHelpText: "",
      ccMapping: "",
      environments: [],
      tags: [],
      platforms: [],
      errors: [],
      controlNumber: "",
      ques_status: 0,
      criticalQuestionSelected: 0,
      criticalQuestions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      expiredIns: [
        { text: "30 days", value: 30 },
        { text: "60 days", value: 60 },
        { text: "90 days", value: 90 },
        { text: "180 days", value: 180 },
        { text: "365 days", value: 365 }
      ],
      controlNos: [],
      msgModaleTitle: "",
      msg: "",
      ques_step_status: "",
      excerpt: ""
    };
  },
  components: {},
  beforeMount() {
    this.ques_id = localStorage.getItem("ques_id");
    this.getQuestionnaire();
    this.getPlatforms();
    this.getEnvironments();
    this.getTags();

    if (this.ques_id > 0) {
      this.getQuestions();
    } else {
      this.getTopics();
    }
  },
  mounted() {},
  computed: {},
  methods: {
    onSubmitQuestion: function() {
      if (
        this.regulationSelected.length <= 0 ||
        this.topicSelected == null ||
        this.questionnaireSelected == null ||
        this.expiredInSelected == null ||
        this.questionText.trim() == "" ||
        this.ccMapping == "" ||
        this.cc_ques_no == ""
      ) {
        this.showMsg("error", "Please fill in the fields marked as asterisk.");
        return;
      }

      if (this.ques_id > 0) {
        this.ques_status = 0;
        this.patchQuestion(1);
      } else {
        this.postQuestion();
      }
    },
    onSubmitControlMapping: async function(evt) {
      evt.preventDefault();

      if (
        this.regulationSelected.length <= 0 ||
        this.topicSelected == null ||
        this.questionnaireSelected == null ||
        this.expiredInSelected == null ||
        this.questionText.trim() == "" ||
        this.ccMapping == "" ||
        this.cc_ques_no == ""
      ) {
        this.showMsg("error", "Please fill in the fields marked as asterisk.");
        return;
      }

      this.ques_status = 1;
      await this.patchQuestion(2);
    },
    resetForm() {
      this.questionText = "";
      this.cc_ques_no = "";
      this.questionHelpText = "";
      this.ccMapping = "";
      this.expiredInSelected = 90;
      this.regulationSelected = [];
      this.topicSelected = null;
      this.questionnaireSelected = 1;
      this.platformSelected = [];
      this.environmentSelected = [];
      this.tagSelected = [];
      this.excerpt = "";
      this.criticalQuestionSelected = 0;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    postQuestion: function() {
      const apiName = "OneAudit";
      const path = "/questions";

      var controlNumbers = [];
      let control_no = [];
      for (var reg_id in this.regulationSelected) {
        control_no = this.$refs[
          "cc_control_num_" + this.regulationSelected[reg_id]
        ][0].localValue.split(",");

        control_no = control_no.filter(function(el) {
          return el != null && el.trim() != "";
        });

        controlNumbers.push({
          regln_id: String(this.regulationSelected[reg_id]),
          ctrl_nos: control_no
        });
      }

      const params = {
        ques_text: this.questionText,
        cc_ques_no: parseInt(this.cc_ques_no),
        topic_id: this.topicSelected,
        questionnaire_id: this.questionnaireSelected,
        cc_mapping: String(this.ccMapping),
        excerpt: String(this.excerpt),
        help_text: this.questionHelpText,
        expiry_date: String(this.expiredInSelected),
        regulations: this.regulationSelected,
        platforms: this.platformSelected,
        environments: this.environmentSelected,
        tags: this.tagSelected,
        critical_ques: String(this.criticalQuestionSelected)
      };

      CCApiService.post(apiName, path, params)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") &&
                response.data.hasOwnProperty("question_added") == true &&
                response.data.question_added == "true" &&
                response.data.hasOwnProperty("id") == true &&
                response.data.id > 0
              ) {
                this.ques_id = response.data.id;
                localStorage.setItem("ques_id", this.ques_id);
                this.e1 = 2;
              } else {
                this.showMsg("error", "Failed to add question");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              if (response.error_code == "cc_mapping_exists") {
                this.showMsg(
                  "error",
                  "CC Integrated Reference number already exist."
                );
              } else {
                var message =
                  response.hasOwnProperty("data") && response.data != undefined
                    ? ", Message: " + response.data.toString()
                    : "";
                this.showMsg(
                  "error",
                  "Failed. Error code: " + response.error_code + message
                );
              }
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          if (
            error.hasOwnProperty("response") == true &&
            error.response != undefined
          ) {
            if (
              error.response.hasOwnProperty("data") == true &&
              error.response.data != undefined
            ) {
              if (
                error.response.data.hasOwnProperty("message") == true &&
                error.response.data.message != undefined
              ) {
                this.showMsg("error", error.response.data.message);
              } else {
                this.showMsg("error", error.response.data);
              }
            } else {
              this.showMsg("error", error.response);
            }
          } else {
            this.showMsg("error", error);
          }
        });
    },
    patchQuestion: function(first_submit) {
      const apiName = "OneAudit";
      const path = "/questions/" + this.ques_id;

      var controlNumbers = [];
      let control_no = [];
      for (var reg_id in this.regulationSelected) {
        control_no = this.$refs[
          "cc_control_num_" + this.regulationSelected[reg_id]
        ][0].localValue.split(",");

        control_no = control_no.filter(function(el) {
          return el != null && el.trim() != "";
        });

        if (first_submit == 2 && control_no.length <= 0) {
          this.showMsg(
            "error",
            "Please fill in the fields marked as asterisk."
          );
          return;
        }

        controlNumbers.push({
          regln_id: String(this.regulationSelected[reg_id]),
          ctrl_nos: control_no
        });
      }

      const params = {
        ques_text: this.questionText,
        cc_ques_no: this.cc_ques_no,
        questionnaire_id: this.questionnaireSelected,
        topic_id: this.topicSelected,
        cc_mapping: String(this.ccMapping),
        excerpt: String(this.excerpt),
        help_text: this.questionHelpText,
        expiry_date: String(this.expiredInSelected),
        regulations: this.regulationSelected,
        platforms: this.platformSelected,
        environments: this.environmentSelected,
        tags: this.tagSelected,
        controls: controlNumbers,
        publish: this.ques_status == 1 ? 1 : 0,
        critical_ques: String(this.criticalQuestionSelected)
      };

      CCApiService.patch(apiName, path, params)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") &&
                response.data.hasOwnProperty("question_updated") == true &&
                response.data.question_updated == "true" &&
                response.data.hasOwnProperty("ques_id") == true &&
                response.data.ques_id > 0
              ) {
                if (this.ques_status == 1) {
                  this.showMsg(
                    "success",
                    "Question details successfully saved."
                  );
                  this.nextClickQuestionList();
                } else {
                  this.ques_id = response.data.ques_id;
                  this.e1 = 2;
                }
              } else {
                this.showMsg("error", "Failed to save question details");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              if (response.error_code == "cc_mapping_exists") {
                this.showMsg(
                  "error",
                  "CC integrated reference number already exist."
                );
              } else {
                var message =
                  response.hasOwnProperty("data") && response.data != undefined
                    ? ", data: " + response.data.toString()
                    : "";
                this.showMsg(
                  "error",
                  "Failed. Error code: " + response.error_code + message
                );
              }
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          if (
            error.hasOwnProperty("response") == true &&
            error.response != undefined
          ) {
            if (
              error.response.hasOwnProperty("data") == true &&
              error.response.data != undefined
            ) {
              if (
                error.response.data.hasOwnProperty("message") == true &&
                error.response.data.message != undefined
              ) {
                this.showMsg("error", error.response.data.message);
              } else {
                this.showMsg("error", error.response.data);
              }
            } else {
              this.showMsg("error", error.response);
            }
          } else {
            this.showMsg("error", error);
          }
        });
    },
    getRegulationNameById: function(id) {
      for (var i = 0; i < this.regulations.length; i++) {
        if (this.regulations[i].value == id) {
          return this.regulations[i].text;
        }
      }
    },
    getRegulations() {
      if (this.questionnaireSelected == null || this.questionnaireSelected <= 0)
        return false;
      const apiName = "OneAudit";
      const path =
        "/regulations?questionnaire_id=" + this.questionnaireSelected;
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                this.regulations = [];
                for (var i = 0; i < response.length; i++) {
                  this.regulations.push({
                    text: response[i].regln_name,
                    value: response[i].regln_id
                  });
                }
                this.regulations.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getQuestions() {
      const apiName = "OneAudit";
      const path =
        this.ques_id > 0 ? "/questions/" + this.ques_id : "/questions";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.questionText =
                    response[i].ques_text != undefined
                      ? response[i].ques_text
                      : "";
                  this.cc_ques_no =
                    response[i].cc_ques_no != undefined
                      ? response[i].cc_ques_no
                      : "";
                  this.ccMapping =
                    response[i].cc_mapping != undefined
                      ? response[i].cc_mapping
                      : "";
                  this.expiredInSelected =
                    response[i].expiry_date != undefined
                      ? response[i].expiry_date
                      : 90;
                  this.questionHelpText =
                    response[i].help_text != undefined
                      ? response[i].help_text
                      : "";
                  this.questionnaireSelected =
                    response[i].questionnaire.id != undefined
                      ? response[i].questionnaire.id
                      : null;
                  this.topicSelected =
                    response[i].topic.id != undefined
                      ? response[i].topic.id
                      : null;
                  this.ques_step_status =
                    response[i].ques_step_status != undefined
                      ? response[i].ques_step_status
                      : "";
                  this.excerpt =
                    response[i].excerpt != undefined ? response[i].excerpt : "";
                  if (response[i].environments != undefined) {
                    for (var j = 0; j < response[i].environments.length; j++) {
                      this.environmentSelected.push(
                        response[i].environments[j].env_id
                      );
                    }
                  }
                  if (response[i].platforms != undefined) {
                    for (var k = 0; k < response[i].platforms.length; k++) {
                      this.platformSelected.push(
                        response[i].platforms[k].plat_id
                      );
                    }
                  }
                  if (response[i].regulations != undefined) {
                    for (var l in response[i].regulations) {
                      this.regulationSelected.push(
                        response[i].regulations[l].regln_id
                      );
                    }
                  }
                  if (response[i].tags != undefined) {
                    for (var t = 0; t < response[i].tags.length; t++) {
                      this.tagSelected.push(response[i].tags[t].tag_id);
                    }
                  }
                  if (response[i].controls != undefined) {
                    this.controlNos = response[i].controls;
                  }
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.getTopics();
        });
    },
    getPlatforms() {
      const apiName = "OneAudit";
      const path = "/platforms";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.platforms.push({
                    text: response[i].plat_name,
                    value: response[i].plat_id
                  });
                }
                this.platforms.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getEnvironments() {
      const apiName = "OneAudit";
      const path = "/environments";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.environments.push({
                    text: response[i].env_name,
                    value: response[i].env_id
                  });
                }
                this.environments.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getQuestionnaire() {
      const apiName = "OneAudit";
      const path = "/questionnaire";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.questionnaires.push({
                    text: response[i].questionnaire_name,
                    value: response[i].questionnaire_id
                  });
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getTopics(change_flag = false) {
      this.getRegulations();
      const apiName = "OneAudit";
      const path = "/questionnaire/" + this.questionnaireSelected + "/topics";
      this.topics = [{ text: "Please Select", value: null }];
      if (this.ques_id <= 0 || change_flag) {
        this.topicSelected = null;
      }
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.topics.push({
                    text: response[i].topic_name,
                    value: response[i].topic_id
                  });
                }
                this.topics.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getTags() {
      const apiName = "OneAudit";
      const path = "/tags";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.tags.push({
                    text: response[i].tag_name,
                    value: response[i].tag_id
                  });
                }
                this.tags.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    showMsg: function(type, msg) {
      this.msgModaleTitle = type == "error" ? "Error" : "Message";
      this.msg = msg;
      this.$refs["msg-modal"].show();
      // alert(msg);
    },
    nextClickQuestionList: function() {
      if (this.ques_status == 1) {
        localStorage.setItem("ques_id", 0);
        this.$router.push({ name: "questionnaire-question-list" });
      }
    }
  }
};
</script>
